<template>
  <div class="expert-recommend">
    <img class="er-title" :src="require('./images/specialist.png')" />
    <div class="er-label">
      <div
        @click="setExpertLabel(index)"
        :class="{'er-label-select':selectExpertId===index}"
        class="er-label-text" v-for="(item,index) in data&&data.labels" :key="index">
        {{ item.name }}
      </div>
    </div>
    <div class="er-list">
      <div class="er-body"
           @click="goExpertDetail(item.id)"
           v-for="(item,index) in list"
           :key="index">
        <div class="er-main">
          <image-loader :width="130" :height="130" :src="item.pic" :mode="'cover'" v-if="item.pic"/>
          <div class="head-fs" v-else>{{item.headName}}</div>
          <div class="erm-des">
            <div class="erm-des-fontA ellipse-1">
              {{ item.name }}
            </div>
            <div class="erm-des-fontA ellipse-1">
              {{ item.pa }}
            </div>
            <div class="erm-des-fontB">
              {{ item.research }}
            </div>
            <div class="erm-des-more">专家详情</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "expertRecommend",
  props: {
    data: Object
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectExpertId = 0;
        }
      }
    }
  },
  data() {
    return {
      selectExpertId: -1,
      list: [
        {name: "倪争技", pa: "上海理工大学", research: "光学仪器研究", pic: require("./images/head/head1.jpg")},
        {name: "张建平", pa: "上海理工大学", research: "能源安全利用", pic: require("./images/head/head2.jpg")},
        {name: "阮锦强", pa: "南京工业大学", research: "复合材料纺锤杆结构优化设计", headName: "阮锦强"},
        {name: "陈强", pa: "山东大学", research: "计量经济学", pic: require("./images/head/head4.jpg")},
        {name: "潘苏", pa: "南京邮电大学", research: "无线通信", pic: "",headName: "潘苏"},
        {name: "宋国强", pa: "常州大学", research: "制药工程", pic: require("./images/head/head6.jpg")},
        {name: "俞元春", pa: "南京林业大学", research: "森林生态系统碳、氮循环", pic: require("./images/head/head7.png")},
        {name: "陈金慧", pa: "南京林业大学", research: "林木遗传与基因工程", pic: require("./images/head/head8.jpg")},
        {name: "毕天姝", pa: "华北电力大学", research: "电力系统保护控制", pic: require("./images/head/head9.jpg")},
        {name: "许浩", pa: "南京林业大学", research: "重点研究绿地系统演变机制", pic: require("./images/head/head10.jpg")},
        {name: "曹加杰", pa: "南京林业大学", research: "园林规划设计",  pic: require("./images/head/曹加杰.jpg")},
        {name: "费文君", pa: "南京林业大学", research: "风景园林规划设计", pic: require("./images/head/费文君.jpg")},
        {name: "胡运宏", pa: "南京林业大学", research: "风景园林理论与历史", pic: require("./images/head/胡运宏.jpg")},
        {name: "何疏悦", pa: "南京林业大学", research: "城市景观社会学", pic: require("./images/head/何疏悦.jpg")},
        {name: "阮宏华", pa: "南京林业大学", research: "土壤碳的生物学与生态学过程", pic: require("./images/head/阮宏华.jpg")},
        {name: "乔维川", pa: "南京林业大学", research: "环境污染治理的理论", pic: require("./images/head/乔维川.png")},
        {name: "鲁长虎", pa: "南京林业大学", research: "野生动植物保护与利用", pic: require("./images/head/鲁长虎.jpg")},
        {name: "王贤荣", pa: "南京林业大学", research: "资源保护与利用的研究与开发", pic: require("./images/head/王贤荣.jpg")},
        {name: "李萍萍", pa: "南京林业大学", research: "农业生物环境工程", pic: require("./images/head/李萍萍.jpg")},
        {name: "栾兆擎", pa: "南京林业大学", research: "湿地生态水文过程", pic: require("./images/head/栾兆擎.jpg")},
        {name: "李胎花", pa: "南京林业大学", research: "环境污染控制理论与技术", pic: require("./images/head/李胎花.jpg")},
        {name: "徐侠", pa: "南京林业大学", research: "全球变化生态学，地下生态学", pic: require("./images/head/徐侠.png")},
        {name: "梅翔", pa: "上海理工大学", research: "致力于水污染控制理论与方法", pic: require("./images/head/梅翔.jpg")},
        {name: "黄正祥", pa: "南京理工大学", research: "新型弹药技术", pic: require("./images/head/黄正祥.jpg")},
        {name: "卞雷祥", pa: "南京理工大学", research: "薄膜敏感材料", pic: require("./images/head/卞雷祥.jpg")},
        {name: "杜宇雷", pa: "南京理工大学", research: "机械工程材料及其制造装备", pic: require("./images/head/杜宇雷.jpg")},
        {name: "袁军堂", pa: "南京理工大学", research: "研究高性能非金属结构材料", pic: require("./images/head/袁军堂.jpg")},
        {name: "李东波", pa: "南京理工大学", research: "光机电一体化", pic: require("./images/head/李东波.jpg")},
        {name: "陈光", pa: "南京理工大学", research: "凝固技术与新材料", pic: require("./images/head/陈光.jpg")},
        {name: "刘伟", pa: "南京理工大学", research: "金属表面构建的复合界面材料", pic: require("./images/head/刘伟.jpg")},
        {name: "谈华平", pa: "南京理工大学", research: "组织再生支架材料", pic: require("./images/head/谈华平.jpg")},
        {name: "杨爱梅", pa: "兰州理工大学", research: "中药新药研发筛选等", pic: require("./images/head/杨爱梅.jpg")},
        {name: "杨军虎", pa: "兰州理工大学", research: "小流量旋喷泵研制", pic: require("./images/head/杨军虎.jpg")},
        {name: "丁雨田", pa: "兰州理工大学", research: "铸造铝基复合材料的研制", pic: require("./images/head/丁雨田.jpg")},
        {name: "龚俊", pa: "兰州理工大学", research: "机电一体化成套设备", pic: require("./images/head/龚俊.jpg")},
        {name: "黎锁平", pa: "兰州理工大学", research: "信号与无线通信领域的研究", pic: require("./images/head/黎锁平.jpg")},
        {name: "谭建荣", pa: "兰州理工大学", research: "机械设计及理论", pic: require("./images/head/谭建荣.jpg")},
        {name: "胡文瑞", pa: "兰州理工大学", research: "微重力科学的研究和发展", pic: require("./images/head/胡文瑞.jpg")},
        {name: "强建国", pa: "兰州理工大学", research: "涡旋式流体机械应用研究", pic: require("./images/head/强建国.jpg")},
        {name: "陈伟", pa: "兰州理工大学", research: "新能源发电技术等", pic: require("./images/head/陈伟.png")},
        {name: "侯尚林", pa: "兰州理工大学", research: "光信息传输与光纤通信", pic: require("./images/head/侯尚林.jpg")},
        {name: "张爱华", pa: "兰州理工大学", research: "模式识别与智能系统", pic: require("./images/head/张爱华.jpg")},
        {name: "杨从新", pa: "兰州理工大学", research: "水力机械理论与设计", pic: require("./images/head/杨从新.jpg")},
        {name: "施梁", pa: "南京工业大学", research: "城市公共环境艺术", pic: require("./images/head/施梁.jpg")},
        {name: "舒欣", pa: "南京工业大学", research: "技术结构建筑设计理论与方法", pic: require("./images/head/舒欣.jpg")},
        {name: "孙政", pa: "南京工业大学", research: "建筑遗产数字化测绘", pic: require("./images/head/孙政.jpg")},
        {name: "杨小山", pa: "南京工业大学", research: "建筑技术科学", pic: require("./images/head/杨小山.jpg")},
        {name: "蒋博雅", pa: "南京工业大学", research: "建筑物全生命周期碳排放", pic: require("./images/head/蒋博雅.jpg")},
        {name: "蔡志昶", pa: "南京工业大学", research: "生态城市规划与设计", pic: require("./images/head/蔡志昶.jpg")},
        {name: "艾欣", pa: "华北电力大学", research: "电力系统分析与控制", pic: require("./images/head/艾欣.jpg")},
      ]
    };
  },
  methods: {
    setExpertLabel(id) {
      this.selectExpertId = id;
    },
    goExpertDetail(id) {
      // this.$router.push({
      //   path: "experts",
      //   query: {
      //     shopId: id
      //   }
      // });
    }
  }
};
</script>

<style scoped>
div{
  /*height: max-content;*/
}
.expert-recommend {
  background-image: url("../../assets/image/index/index_bga.png");
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto!important;
}

.er-title {
  margin-top: 50px;
}

.er-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1250px;
}

.er-body {
  width: 410px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.er-main {
  width: 380px;
  height: 170px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.er-main:hover {
  width: 390px;
  height: 180px;
  box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.5);
}

.erm-img {
  width: 130px;
  height: 130px;
  border-radius: 4px;
}

.er-label {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E5E8ED;
  height: 35px;
  margin-top: 20px;
  justify-content: center;
}

.er-label-text {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  width: 200px;
  display: flex;
  justify-content: center;
  height: 35px;
  cursor: pointer;
}

.er-label-select {
  color: rgba(24, 144, 255, 1);
  border-bottom: 1px solid rgba(24, 144, 255, 1);
}

.erm-des {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.erm-des-fontA {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
}

.erm-des-fontB {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.erm-des-more {
  width: 76px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #1890FF;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.er-more {
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  margin-top: 45px;
  margin-bottom: 50px;
  cursor: pointer;
}
.head-fs{
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #FFFFFF;
  background: #1890FF;
}
</style>
